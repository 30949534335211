exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brick-identification-js": () => import("./../../../src/pages/brick-identification.js" /* webpackChunkName: "component---src-pages-brick-identification-js" */),
  "component---src-pages-bricks-js": () => import("./../../../src/pages/bricks.js" /* webpackChunkName: "component---src-pages-bricks-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */)
}

